import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "@/contexts/AuthContext";
import CircularLoader from "@/components/loading/LoadingComponent";

interface GuardProps {
    children: React.ReactNode;
}

const SubscriptionValidGuard = ({ children }: GuardProps) => {
    console.log("Executing SubscriptionValidGuard");

    const { subscription, loading } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Executing SubscriptionValidGuard useEffect");

        // If not loading and subscription is not active or trialing
        // Check if subscription is past due
        // If subscription is past due, redirect to /payment-issue
        // If subscription is any other status or not found, navigate to /checkout
        // If subscription is active or trialing, render children

        if (!loading) {
            if (
                subscription &&
                subscription.status === "past_due"
            ) {
                navigate("/payment-issue");
            } else if (
                !subscription ||
                subscription.status !== "active" &&
                subscription.status !== "trialing"
            ) {
                navigate("/checkout");
            }
        }
    }, [subscription, loading, navigate]);

    if (loading) {
        return <CircularLoader />;
    }

    return subscription &&
        (subscription.status === "active" ||
            subscription.status === "trialing") ? (
        <>{children}</>
    ) : null;
};

export default SubscriptionValidGuard;
