import React, { Component, ReactNode } from "react";
import * as Sentry from "@sentry/react";
import ErrorPage from "@/pages/ErrorPage";

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    eventId?: string;
    error?: Error;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, eventId: undefined, error: undefined };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error }; // Store the error in state
    }

    async componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error("Error caught by Global ErrorBoundary:", error, errorInfo);

        if (process.env.NODE_ENV === "production") {
            console.log("Sentry: Attempting to log error...");

            try {
                const eventId = await Sentry.captureException(error, {
                    extra: { componentStack: errorInfo.componentStack },
                });

                console.log("Sentry Event ID:", eventId);

                this.setState({ eventId, error });
            } catch (err) {
                console.error("Failed to send error to Sentry:", err);
            }
        }
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage sentryEventId={this.state.eventId} error={this.state.error} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
