import React from "react";
import { useNavigate } from "react-router-dom";
import LogoComponent from "@/components/logo/LogoComponent";

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate("/"); // Navigate back to the home page
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-2 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <LogoComponent className="mx-auto h-20 w-auto" />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
                    404 - Page Not Found
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="w-full rounded-lg bg-white p-8 pb-3 shadow-md dark:bg-gray-800">
                    <p className="mb-6 text-center text-lg dark:text-white">
                        Oops! The page you are looking for doesn't exist or has been moved.
                    </p>

                    <div className="mb-6 flex justify-center">
                        <button
                            onClick={handleGoHome}
                            className="rounded-md bg-gray-200 px-4 py-2 text-gray-900 hover:bg-gray-300 focus:outline-none dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
                        >
                            Go to Home
                        </button>
                    </div>

                    <p className="text-center text-sm dark:text-gray-400">
                        Need help?{" "}
                        <a
                            href="https://fliplytics.co.uk/contact"
                            className="hover:text-brand-light font-semibold text-brand"
                        >
                            Contact our support team
                        </a>
                        .
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
