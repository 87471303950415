import React, { useEffect, useContext, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PAYMENT_ISSUE_STATUS_QUERY } from "@/graphql/queries/paymentQueries";
import { CREATE_PAYMENT_UPDATE_SESSION_MUTATION } from "@/graphql/mutations/paymentMutations";
import { AuthContext } from "@/contexts/AuthContext";
import CircularLoader from "@/components/loading/LoadingComponent";
import LogoComponent from "@/components/logo/LogoComponent";
import { useStripe } from "@stripe/react-stripe-js";

const PaymentIssuePage: React.FC = () => {
    const { subscription, loading: authLoading } = useContext(AuthContext);
    const { data, loading, error } = useQuery(GET_PAYMENT_ISSUE_STATUS_QUERY, {
        fetchPolicy: "network-only",
    });
    const [createPaymentUpdateSession, { loading: updating }] = useMutation(
        CREATE_PAYMENT_UPDATE_SESSION_MUTATION
    );

    const stripe = useStripe();

    const [message, setMessage] = useState<string | null>(null);
    const [status, setStatus] = useState<string | null>(null);
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const [billingPortalUrl, setBillingPortalUrl] = useState<string | null>(null);

    // Redirect to dashboard if subscription is active or trialing
    useEffect(() => {
        if (!authLoading && subscription && (subscription.status === "active" || subscription.status === "trialing")) {
            window.location.href = "/dashboard";
        }
    }, [authLoading, subscription]);

    useEffect(() => {
        if (data?.getPaymentIssueStatus) {
            setStatus(data.getPaymentIssueStatus.status);
            setMessage(data.getPaymentIssueStatus.message);
        }
    }, [data]);

    const handlePaymentUpdate = async () => {
        try {
            const { data } = await createPaymentUpdateSession();
            const clientSecret = data?.createPaymentUpdateSession?.clientSecret || null;
            const billingPortalUrl = data?.createPaymentUpdateSession?.billingPortalUrl || null;

            setClientSecret(clientSecret);
            setBillingPortalUrl(billingPortalUrl);

            if (billingPortalUrl) {
                // Redirect to Stripe Billing Portal to update payment method
                window.location.href = billingPortalUrl;
                return;
            }

            if (clientSecret && stripe) {
                // Handle authentication via Stripe.js
                console.log("Confirming payment intent...");
                const { error } = await stripe.confirmCardPayment(clientSecret);
                
                if (error) {
                    console.error("Payment authentication failed:", error.message);
                } else {
                    window.location.href = "/dashboard"; // Payment successful, redirect to dashboard
                }
            }
        } catch (error) {
            console.error("Error updating payment method", error);
        }
    };

    if (loading || authLoading) return <CircularLoader />;
    if (error) return <p>Error loading payment status.</p>;

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <LogoComponent className="mx-auto h-20 w-auto" />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
                    Payment Issue
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="w-full rounded-lg bg-white p-8 shadow-md dark:bg-gray-800">
                    <h3 className="mb-4 text-xl font-semibold dark:text-white">
                        {message}
                    </h3>

                    <p className="mb-6 text-sm dark:text-gray-400">
                        Your last payment attempt was unsuccessful. Please update your payment details or authenticate the payment to continue your subscription.
                    </p>

                    <button
                        onClick={handlePaymentUpdate}
                        className={`flex w-full justify-center rounded-md bg-red-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 ${updating ? "cursor-not-allowed opacity-50" : "hover:bg-red-600"}`}
                        disabled={updating}
                    >
                        {updating ? "Processing..." : "Fix Payment Issue"}
                    </button>

                    <div className="mt-10">
                        <p className="text-center text-sm dark:text-gray-400">
                            Need help?{" "}
                            <a
                                href="https://fliplytics.co.uk/contact"
                                className="hover:text-brand-light font-semibold text-brand"
                            >
                                Contact our support team
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentIssuePage;
