import React from "react";
import { useNavigate } from "react-router-dom";
import LogoComponent from "@/components/logo/LogoComponent";

interface ErrorPageProps {
    sentryEventId?: string;
    error?: Error;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ sentryEventId, error }) => {
    const navigate = useNavigate();
    const isDev = process.env.NODE_ENV === "development";

    return (
        <div className="flex min-h-screen flex-col items-center justify-center px-6 py-12">
            <LogoComponent className="mb-6 h-20 w-auto" />
            <div className="w-full max-w-4xl rounded-lg bg-white p-8 shadow-lg dark:bg-gray-800">
                <h2 className="text-center text-3xl font-bold dark:text-white">
                    Oops! Something Went Wrong
                </h2>
                <p className="mt-4 text-center text-lg text-gray-700 dark:text-gray-300">
                    An unexpected error occurred. Please try again later.
                </p>

                {/* Debug Info (Development Mode) */}
                {isDev && error ? (
                    <div className="mt-6 rounded-lg bg-gray-100 p-4 dark:bg-gray-900">
                        <h3 className="mb-2 text-lg font-semibold text-red-600 dark:text-red-400">
                            Debug Info:
                        </h3>
                        <pre className="max-h-64 overflow-auto whitespace-pre-wrap text-sm text-red-700 dark:text-red-300">
                            {error.stack}
                        </pre>
                    </div>
                ) : sentryEventId ? (
                    <p className="mt-4 text-center text-sm text-gray-500 dark:text-gray-400">
                        Error ID: <strong>{sentryEventId}</strong>
                    </p>
                ) : null}

                {/* Actions */}
                <div className="mt-6 flex flex-col items-center space-y-3">
                    <button
                        onClick={() => navigate("/")}
                        className="w-full max-w-xs rounded-md bg-gray-200 px-4 py-2 text-gray-900 hover:bg-gray-300 focus:outline-none dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
                    >
                        Go to Home
                    </button>

                    <a
                        href="https://fliplytics.co.uk/contact"
                        className="text-sm font-semibold text-brand hover:text-brand-light"
                    >
                        Contact Support
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
